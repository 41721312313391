import {Currency} from 'src/types/api'

export const CURRENCY_SYMBOLS: {[key in Currency]: string} = {
  eur: '€',
  usd: '$',
  pln: 'zł',
  gbp: '£',
  try: '₺',
} as const

export const TIPS_FIXED_AMOUNT_ID = 'fixed-amount' as const

export const TRANSLATIONS_KEYS = {
  'Timeslot.Info': 'Timeslot.Info',
  'Timeslot.InfoLower': 'Timeslot.InfoLower',
} as const

export const IS_STAGING = process.env.VITE_APP_ENV === 'staging' || window.location.origin === 'https://test.jammi.lt'

export const FILE_SIZE = 'fileSize' as const

export const PHONE_NUMBER = 'phoneNumber' as const

export const MAX_UPLOAD_FILE_SIZE = 10000000 as const

export const GEOLOCATION_POSITION_ERROR_CODES = {
  GENERAL: 1,
  PERMISSION_DENIED: 2,
} as const

export const MAIN_JAMMI_HOST = 'https://jammi.lt' as const

export const JAMMI_HOSTS = {
  'https://tauro-vilnius.jammi.lt': 'https://tauro-vilnius.jammi.lt',
  'https://tymoturgus-vilnius.jammi.lt': 'https://tymoturgus-vilnius.jammi.lt',
  'https://sauletekio-vilnius.jammi.lt': 'https://sauletekio-vilnius.jammi.lt',
  'https://ateities-vilnius.jammi.lt': 'https://ateities-vilnius.jammi.lt',
  'https://blindziu-vilnius.jammi.lt': 'https://blindziu-vilnius.jammi.lt',
  'https://ukmerges-vilnius.jammi.lt': 'https://ukmerges-vilnius.jammi.lt',
  'https://medeinos-vilnius.jammi.lt': 'https://medeinos-vilnius.jammi.lt',
  'https://talino-vilnius.jammi.lt': 'https://talino-vilnius.jammi.lt',
  'https://vyduno-vilnius.jammi.lt': 'https://vyduno-vilnius.jammi.lt',
  'https://pilkalnio-vilnius.jammi.lt': 'https://pilkalnio-vilnius.jammi.lt',
  'https://luksio-vilnius.jammi.lt': 'https://luksio-vilnius.jammi.lt',
  'https://naugarduko-vilnius.jammi.lt': 'https://naugarduko-vilnius.jammi.lt',
  'https://ozas-vilnius.jammi.lt': 'https://ozas-vilnius.jammi.lt',
  'https://tilzes-klaipeda.jammi.lt': 'https://tilzes-klaipeda.jammi.lt',
  'https://taikos-klaipeda.jammi.lt': 'https://taikos-klaipeda.jammi.lt',
  'https://stanaiciu-mazuriskes.jammi.lt': 'https://stanaiciu-mazuriskes.jammi.lt',
  'https://tilzes-siauliai.jammi.lt': 'https://tilzes-siauliai.jammi.lt',
} as const
