/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface ActiveOrderContract {
  /** @format uuid */
  id?: string
  type?: OrderType
  userPhoneNumber?: string | null
  name?: string | null
  commentToKitchen?: string | null
  products?: ActiveOrderProductContract[] | null
  invoiceEmail?: string | null
  isUserAgeConfirmed?: boolean
  companyInformation?: CompanyInformationContract
  /** @format double */
  totalPrice?: number
  /** @format double */
  userVisiblePrice?: number | null
  /** @format double */
  serviceFeeAmount?: number
  status?: OrderStatus
  /** @format double */
  startTimeStamp?: number
  paymentType?: PaymentType
  /** @format double */
  loyaltyDiscountAmount?: number
  /** @format double */
  loyaltyPaidAmount?: number
  number?: string | null
  circleKXepsOrder?: string | null
  /** @format double */
  discount?: number
  paymentStatus?: PaymentStatus
}

export interface ActiveOrderOptionsSetContract {
  /** @format uuid */
  id?: string
  title?: string | null
  type?: OptionSetType
  /** @format int32 */
  minSelections?: number
  /** @format int32 */
  maxSelections?: number
  options?: ActiveOrderProductContract[] | null
}

export interface ActiveOrderProductContract {
  /** @format uuid */
  id?: string
  /** @format uuid */
  orderProductId?: string
  title?: string | null
  /** @format double */
  price?: number
  /** @format int32 */
  count?: number
  productType?: ProductType
  loyaltyEmail?: string | null
  productAction?: ProductAction
  /** @format uuid */
  categoryId?: string
  /** @format double */
  discountAmount?: number
  /** @format int32 */
  ageToComply?: number | null
  enableKitchenPrinter?: boolean
  optionSets?: ActiveOrderOptionsSetContract[] | null
}

export interface AgeVerificationContract {
  verified?: boolean
  /** @format int32 */
  age?: number
  ageEstimate?: string | null
  message?: string | null
}

export interface AgeVerificationImageUploadResultContract {
  imageName?: string | null
}

export enum AgeVerificationType {
  Self = 'self',
  Cashier = 'cashier',
  Yoti = 'yoti',
  YotiLight = 'yotiLight',
  CashierStatic = 'cashierStatic',
}

export interface AppraisalContract {
  /** @format uuid */
  id?: string
  /** @format uuid */
  tabletId?: string
  status?: AppraisalStatus
  paymentStatus?: PaymentStatus
  /** @format uuid */
  orderId?: string | null
  /** @format int32 */
  rating?: number
  reason?: string | null
  /** @format double */
  tipAmount?: number
  externalPaymentLink?: string | null
}

export enum AppraisalPaymentType {
  WebTransfer = 'webTransfer',
  WebCard = 'webCard',
}

export enum AppraisalStatus {
  Created = 'created',
  Finished = 'finished',
  Canceled = 'canceled',
}

export interface BarcodeResultClientCodeContract {
  /** @format int64 */
  clientCode?: number
  /** @format double */
  thisMonthSpent?: number | null
  /** @format uuid */
  id?: string
}

export interface BarcodeResultContract {
  type?: BarcodeType
  promoCode?: string | null
  promoCodeInfo?: BarcodeResultPromoCodeInfoContract
  product?: ProductContract
  upsellProducts?: ProductContract[] | null
  isCovidPassValid?: boolean
  loyaltyCardInfo?: BarcodeResultLoyaltyCardContract
  clientCodeInfo?: BarcodeResultClientCodeContract
}

export interface BarcodeResultLoyaltyCardContract {
  title?: string | null
  code?: string | null
  /** @format double */
  thisMonthSpent?: number | null
}

export interface BarcodeResultPromoCodeInfoContract {
  promoCode?: string | null
  /** @format uuid */
  promoCodeId?: string | null
  /** @format double */
  thisMonthSpent?: number | null
}

export enum BarcodeType {
  Product = 'product',
  PromoCode = 'promoCode',
  TabletInfo = 'tabletInfo',
  CovidPass = 'covidPass',
  Logout = 'logout',
  LoyaltyCard = 'loyaltyCard',
  ClientCode = 'clientCode',
}

export interface BaseProductContract {
  /** @format uuid */
  id?: string
  title?: string | null
  description?: string | null
  imageName?: string | null
  isDisabled?: boolean
  categoryTitle?: string | null
}

export interface BooleanContract {
  value?: boolean
}

export interface CalculateContract {
  /**
   * @format int32
   * @min 0
   * @exclusiveMin true
   */
  weight: number
  order: CreateOrderContract
}

export interface CalculatedWeightProductContract {
  product?: ProductContract
}

export interface CallingCodeContract {
  /** @format uuid */
  id?: string
  code?: string | null
  countryName?: string | null
  countryImageName?: string | null
  isDefault?: boolean
}

export enum CardOverlay {
  SidewayGradient = 'sidewayGradient',
  StraightGradient = 'straightGradient',
  Straight = 'straight',
  Curved = 'curved',
}

export interface CashierAgeVerificationContract {
  roomName?: string | null
  /** @format int32 */
  ageToVerify?: number
  verificationId?: string | null
}

export interface CashierAgeVerificationResultContract {
  /** @format uuid */
  tabletId?: string
  verificationId?: string | null
}

export interface CatalogContract {
  products?: ProductContract[] | null
  upsellProducts?: ProductContract[] | null
  categories?: ProductCategoryContract[] | null
  tags?: TagContract[] | null
}

export interface CheckInvoiceOrderContract {
  /**
   * @minLength 12
   * @maxLength 12
   */
  orderName: string
  /**
   * @format uuid
   * @minLength 1
   */
  restaurantId: string
}

export interface CircleKXepsVoucherContract {
  voucherId?: string | null
  /** @format int32 */
  expiryYYMM?: number
  /** @format int32 */
  promotionId?: number
  text?: string | null
  /** @format int32 */
  amount?: number
  /** @format int32 */
  deferrable?: number
  /** @format int32 */
  displayOnly?: number
  expiryTxt?: string | null
}

export interface CompanyInformationContract {
  /** @minLength 1 */
  name: string
  /** @minLength 1 */
  code: string
  vatCode?: string | null
  /** @minLength 1 */
  address: string
}

export interface ConnectTabletContract {
  /** @minLength 1 */
  deviceId: string
  /** @minLength 1 */
  firebaseId: string
  iosVersion?: string | null
  deviceName?: string | null
  isCashier?: boolean | null
}

export interface CovidPassContract {
  firstName?: string | null
  lastName?: string | null
  /** @format int32 */
  birthYear?: number
  /** @format double */
  validTo?: number
  /** @format double */
  issuedAt?: number
  passType?: PassType
}

export interface CovidPassResponseContract {
  success?: boolean
  message?: string | null
  covidPass?: CovidPassContract
}

export enum CovidScanType {
  None = 'none',
  Table = 'table',
  TakeAway = 'takeAway',
  Both = 'both',
}

export interface CreateAnonymousLoyaltyCardBulkContract {
  /**
   * @format int32
   * @min 1
   */
  count: number
  /**
   * @format uuid
   * @minLength 1
   */
  customizationId: string
  discount: CreateLoyaltyDetailsContract
  restaurantIds?: string[] | null
  companyName?: string | null
  companyCode?: string | null
  description?: string | null
}

export interface CreateAppraisalContract {
  /** @format uuid */
  tabletId?: string
  /** @format uuid */
  orderId?: string | null
  /**
   * @format int32
   * @max 5
   */
  rating?: number
  reason?: string | null
  /** @format double */
  tipAmount?: number
  paymentType?: AppraisalPaymentType
}

export interface CreateLoyaltyCardBulkContract {
  /** @minItems 1 */
  emails: string[]
  /**
   * @format uuid
   * @minLength 1
   */
  customizationId: string
  discount: CreateLoyaltyDetailsContract
  restaurantIds?: string[] | null
  companyName?: string | null
  companyCode?: string | null
  description?: string | null
}

export interface CreateLoyaltyCardCustomizationContract {
  /** @minLength 1 */
  organizationName: string
  /** @minLength 1 */
  name: string
  /** @minLength 1 */
  appleLogoUrl: string
  /** @minLength 1 */
  appleStripImageUrl: string
  /** @minLength 1 */
  googleLogoUrl: string
  /** @minLength 1 */
  googleStripImageUrl: string
  /** @maxLength 7 */
  backgroundColor?: string | null
  /**
   * @format email
   * @minLength 1
   */
  email: string
  webpage?: string | null
  phoneNumber?: string | null
  font?: string | null
  walletCardTitle?: string | null
  /** @format int32 */
  validForMonths?: number | null
  firstHeaderText?: string | null
  firstBodyText?: string | null
  firstViewCardLinkText?: string | null
  secondHeaderText?: string | null
  secondBodyText?: string | null
  secondViewCardLinkText?: string | null
  bottomBodyText?: string | null
  footerText?: string | null
  creditsLeftText?: string | null
  emailSenderName?: string | null
}

export interface CreateLoyaltyDetailsContract {
  /** @format double */
  maxAmount?: number | null
  /** @format int32 */
  orderCount?: number | null
  /** @format int32 */
  dailyOrderCount?: number | null
  /** @format int32 */
  totalAmount?: number | null
  /** @format int32 */
  totalMonthlyAmount?: number | null
  /** @format double */
  validTo?: number
}

export interface CreateOrderContract {
  type?: OrderType
  userPhoneNumber?: string | null
  paymentType?: PaymentType
  paymentId?: string | null
  commentToKitchen?: string | null
  promoCode?: string | null
  /** @format double */
  userVisiblePrice?: number | null
  /** @format double */
  serviceFeeAmount?: number
  /** @format int64 */
  clientCode?: number | null
  isUserAgeConfirmed?: boolean
  /** @format double */
  takeAwayDiscountAmount?: number
  /** @minItems 1 */
  products: CreateOrderProductContract[]
  sales?: UpsellInfoContract[] | null
  checkoutUpsellProductIds?: string[] | null
  pagerNumber?: string | null
  invoiceEmail?: string | null
  loyaltyCode?: string | null
  guestIdentification?: string | null
  /** @format uuid */
  timeSlotReservationId?: string | null
  companyInformation?: CompanyInformationContract
  /** @format uuid */
  id?: string | null
  /** @format double */
  tipAmount?: number
}

export interface CreateOrderOptionsSetContract {
  /** @format uuid */
  id?: string
  /** @format int32 */
  order?: number
  options?: CreateOrderProductContract[] | null
}

export interface CreateOrderProductContract {
  /**
   * @format uuid
   * @minLength 1
   */
  id: string
  /** @format uuid */
  orderProductId?: string
  /** @format double */
  price?: number
  /**
   * @format int32
   * @min 0
   * @exclusiveMin true
   */
  count?: number
  addedFrom?: ProductAddedFrom
  productType?: ProductType
  /** @format uuid */
  addedFromId?: string | null
  loyaltyEmail?: string | null
  productAction?: ProductAction
  optionSets?: CreateOrderOptionsSetContract[] | null
}

export interface CredentialsContract {
  /**
   * @format email
   * @minLength 1
   */
  email: string
  /** @minLength 1 */
  password: string
}

export enum Currency {
  Eur = 'eur',
  Usd = 'usd',
  Pln = 'pln',
  Gbp = 'gbp',
  Try = 'try',
}

export enum CustomPageAccessType {
  UrlAndSidebar = 'urlAndSidebar',
  Url = 'url',
  Hidden = 'hidden',
}

export enum CustomPageType {
  Menu = 'menu',
  CustomHtml = 'customHtml',
  VenueList = 'venueList',
  Redirect = 'redirect',
  ApplicationForm = 'applicationForm',
  ReviewForm = 'reviewForm',
  MenuFooter = 'menuFooter',
  ClosedVenuePlaceholder = 'closedVenuePlaceholder',
  InvoiceForm = 'invoiceForm',
}

export interface CustomWebsiteContract {
  /** @format uuid */
  id?: string
  pages?: CustomWebsitePageContract[] | null
  primaryTextColor?: string | null
  altPrimaryTextColor?: string | null
  secondaryTextColor?: string | null
  errorTextColor?: string | null
  selectedOptionTextColor?: string | null
  titleOrnamentColor?: string | null
  successColor?: string | null
  firstItemTextColor?: string | null
  secondItemTextColor?: string | null
  firstItemColor?: string | null
  secondItemColor?: string | null
  thirdItemColor?: string | null
  fourthItemColor?: string | null
  backgroundColor?: string | null
  primaryFont?: string | null
  secondaryFont?: string | null
  favicon?: string | null
  pageTitle?: string | null
  pageDescription?: string | null
  showBrandOnlyInSidebar?: boolean
  facebookUrl?: string | null
  instagramUrl?: string | null
  linkedInUrl?: string | null
  twitterUrl?: string | null
  privacyPolicyUrl?: string | null
  termsConditionsUrl?: string | null
  gtmId?: string | null
  generalStyle?: string | null
  metaPixelId?: string | null
}

export interface CustomWebsitePageContract {
  /** @format uuid */
  id?: string
  title?: string | null
  path?: string | null
  /** @format int32 */
  order?: number
  type?: CustomPageType
  accessType?: CustomPageAccessType
  html?: string | null
  redirectUrl?: string | null
  headerText?: string | null
  subHeaderText?: string | null
  metaTitle?: string | null
  metaDescription?: string | null
}

export interface CustomizationProfileContract {
  showCardDescription?: boolean
  primaryButtonColor?: string | null
  primaryButtonTextColor?: string | null
  secondaryButtonColor?: string | null
  secondaryButtonTextColor?: string | null
  secondaryButtonSelectedColor?: string | null
  secondaryButtonSelectedTextColor?: string | null
  quickAddButtonColor?: string | null
  quickAddButtonIconColor?: string | null
  cartButtonColor?: string | null
  cartButtonTextColor?: string | null
  cartBadgeColor?: string | null
  cartBadgeTextColor?: string | null
  secondaryColor?: string | null
  buttonBarPrimaryBackgroundColor?: string | null
  buttonBarPrimaryIconColor?: string | null
  buttonBarSecondaryBackgroundColor?: string | null
  buttonBarSecondaryIconColor?: string | null
  buttonBarLeftLogo?: string | null
  buttonBarRightLogo?: string | null
  buttonBarText?: string | null
  backgroundImage?: string | null
  backgroundColor?: string | null
  cardOverlayColor?: string | null
  cardMainTextColor?: string | null
  cardDiscountTextColor?: string | null
  cardShadowColor?: string | null
  cardSelectedColor?: string | null
  productsHeaderColor?: string | null
  /** @format double */
  cardOverlayAlpha?: number
  orderLoaderColor?: string | null
  cardOverlay?: CardOverlay
  cartButtonShadowColor?: string | null
  cardScannerBackgroundColor?: string | null
  phoneOrderButtonColor?: string | null
  phoneOrderButtonShadowColor?: string | null
  phoneOrderButtonIconColor?: string | null
  phoneOrderButtonTextColor?: string | null
}

export enum DayOfWeek {
  Sunday = 'sunday',
  Monday = 'monday',
  Tuesday = 'tuesday',
  Wednesday = 'wednesday',
  Thursday = 'thursday',
  Friday = 'friday',
  Saturday = 'saturday',
}

export interface DeliverectResponseContract {
  posOrderId?: string | null
  posReceiptId?: string | null
}

export interface DocScanSessionContract {
  sessionId?: string | null
  sessionToken?: string | null
}

export interface DurationsReportContract {
  /** @format uuid */
  id?: string
  clientTitle?: string | null
  commentToKitchen?: string | null
  /** @format int32 */
  number?: number
  /** @format double */
  startDate?: number
  /** @format double */
  readyDate?: number | null
  /** @format int32 */
  durationSeconds?: number
  /** @format uuid */
  organizationId?: string
  /** @format uuid */
  restaurantId?: string
  restaurantTitle?: string | null
  isScanned?: boolean
  type?: OtOrderType
  name?: string | null
}

export interface DynamicOrderResultContract {
  order?: OrderContract
  errorProductIds?: string[] | null
}

export enum EmpiReceiptTaxType {
  Default = 'default',
  Unused = 'unused',
  ZeroTax = 'zeroTax',
  Discounted = 'discounted',
  Unused5 = 'unused5',
  Unused6 = 'unused6',
  Unused7 = 'unused7',
  Unused8 = 'unused8',
}

export interface ErrorContract {
  /** @format int32 */
  errorCode: number
  /** @minLength 1 */
  message: string
  /** @format int32 */
  internalErrorCode?: number
  validationErrors?: ValidationErrorContract[] | null
  orderValidationError?: OrderValidationErrorContract
}

export interface ExternalOrderContract {
  /** @minLength 1 */
  restaurantId: string
  commentToKitchen?: string | null
  /** @format int32 */
  orderNumber?: number
  /** @format double */
  totalPrice?: number
  type?: string | null
  paymentStatus?: PaymentStatus
  /** @minItems 1 */
  orderProducts: ExternalOrderProductContract[]
}

export interface ExternalOrderOptionContract {
  /**
   * @format int32
   * @min 0
   * @exclusiveMin true
   */
  amount?: number
  /** @format double */
  price?: number
  /** @minLength 1 */
  title: string
  taxType?: EmpiReceiptTaxType
}

export interface ExternalOrderProductContract {
  /**
   * @format int32
   * @min 0
   * @exclusiveMin true
   */
  amount?: number
  /** @format double */
  price?: number
  /** @minLength 1 */
  title: string
  taxType?: EmpiReceiptTaxType
  modifiers?: ExternalOrderOptionContract[] | null
}

export enum FeedbackOrigin {
  Tablet = 'tablet',
  QrCode = 'qrCode',
  Wolt = 'wolt',
}

export enum FiscalLineIndentAlign {
  Left = 'Left',
  Center = 'Center',
  Right = 'Right',
}

export enum FiscalLineIndentMeasure {
  Value = '%',
  Em = 'em',
  Px = 'px',
  Mm = 'mm',
}

export enum FiscalLineIndentOverflow {
  Hidden = 'Hidden',
  Wrap = 'Wrap',
}

export enum FiscalLineType {
  Text = 'Text',
  Barcode = 'Barcode',
  Image = 'Image',
}

export interface FiscalReceiptContract {
  lines?: FiscalReceiptLineContract[] | null
  tabStops?: FiscalReceiptTabStopContract[] | null
}

export interface FiscalReceiptLineContract {
  type?: FiscalLineType
  content?: string | null
  intent?: string | null
}

export interface FiscalReceiptTabStopContract {
  name?: string | null
  indents?: FiscalReceiptTabStopIndentContract[] | null
}

export interface FiscalReceiptTabStopIndentContract {
  align?: FiscalLineIndentAlign
  /** @format double */
  offset?: number
  measure?: FiscalLineIndentMeasure
  overflow?: FiscalLineIndentOverflow
}

export interface GenerateInvoiceOrderEmailContract {
  /**
   * @minLength 12
   * @maxLength 12
   */
  orderName: string
  /** @minLength 1 */
  email: string
  /**
   * @format uuid
   * @minLength 1
   */
  restaurantId: string
  companyInformation: CompanyInformationContract
}

export interface GenerateInvoiceOrderPdfContract {
  /**
   * @minLength 12
   * @maxLength 12
   */
  orderName: string
  /** @minLength 1 */
  email: string
  /**
   * @format uuid
   * @minLength 1
   */
  restaurantId: string
  companyInformation: CompanyInformationContract
}

export interface GetHistoryReportContract {
  /** @format double */
  fromTimestamp: number
  /** @format double */
  toTimestamp: number
  /**
   * @format uuid
   * @minLength 1
   */
  organizationId: string
}

export interface GetProductByBarcodeContract {
  /** @minLength 1 */
  barcode: string
  isManualEntry?: boolean | null
}

export interface GetProductsContract {
  /** @minItems 1 */
  productIds: string[]
}

export interface GetReportByOrganizationContract {
  /**
   * @format uuid
   * @minLength 1
   */
  organizationId: string
}

export interface GetReportContract {
  /** @format double */
  fromTimestamp: number
  /**
   * @format uuid
   * @minLength 1
   */
  organizationId: string
  reportDuration?: ReportDuration
}

export interface IlunchTvCatalogContract {
  logoImageName?: string | null
  adImageName?: string | null
  products?: TvProductContract[] | null
  firstScreenProducts?: TvProductContract[] | null
  secondScreenProducts?: TvProductContract[] | null
}

export enum InputFieldType {
  None = 'none',
  Required = 'required',
  Optional = 'optional',
}

export interface InvoiceOrderContract {
  name?: string | null
  restaurant?: string | null
  /** @format double */
  startTimeStamp?: number
  /** @format double */
  totalPrice?: number
}

export interface KdsCreateBaseOrderContract {
  /** @format int32 */
  number?: number
  /** @format uuid */
  locationId?: string
}

export interface KdsDisplayOrderContract {
  /** @format uuid */
  id?: string
  name?: string | null
  /** @format int32 */
  number?: number
  status?: KdsOrderStatus
  readyOrderBackgroundColor?: string | null
  createdOrderBackgroundColor?: string | null
  orderProducts?: KdsOrderProductContract[] | null
  commentToKitchen?: string | null
  type?: OrderType
  kitchenStatus?: KdsOrderStatus
  table?: string | null
  paymentStatus?: KdsPaymentStatus
  origin?: OrderOrigin
  guestIdentification?: string | null
  printStatus?: PrintStatus
}

export interface KdsOrderProductContract {
  /** @format uuid */
  id?: string
  title?: string | null
  /** @format int32 */
  count?: number
  /** @format uuid */
  categoryId?: string | null
  options?: KdsOrderProductOptionContract[] | null
}

export interface KdsOrderProductOptionContract {
  /** @format uuid */
  id?: string
  title?: string | null
  /** @format int32 */
  count?: number
}

export enum KdsOrderStatus {
  None = 'none',
  Created = 'created',
  Ready = 'ready',
  Finished = 'finished',
}

export enum KdsPaymentStatus {
  Paid = 'paid',
  Unpaid = 'unpaid',
}

export interface KdsTvOrderContract {
  /** @format uuid */
  id?: string
  /** @format int32 */
  number?: number
  status?: KdsOrderStatus
}

export interface KdsUserAuthenticationContract {
  token?: string | null
  user?: KdsUserProfileContract
}

export interface KdsUserProfileContract {
  /** @format uuid */
  id?: string
  email?: string | null
  description?: string | null
  isAdmin?: boolean
  locations?: KdsVenueContract[] | null
}

export interface KdsVenueContract {
  /** @format uuid */
  id?: string
  title?: string | null
  /** @format int32 */
  readyOrdersExpirationMinutes?: number
  /** @format int32 */
  timeZoneOffset?: number
  readyOrderBackgroundColor?: string | null
  createdOrderBackgroundColor?: string | null
  orderReadyMessage?: string | null
  enableFinishOrderButton?: boolean
  printSelfServiceOrders?: boolean
  printWebOrders?: boolean
  printQrOrders?: boolean
  printWoltOrders?: boolean
  printBoltOrders?: boolean
  showBoltOrders?: boolean
  showWoltOrders?: boolean
  showQrOrders?: boolean
  showSelfServiceOrders?: boolean
  printOrderType?: boolean
  enableEmailButton?: boolean
  enableCashOrdersConfirmation?: boolean
  enableReceiptLogoPrinting?: boolean
  /** @format int32 */
  createdOrdersExpirationMinutes?: number | null
  receiptLogoImageName?: string | null
}

export interface KitchenAuthenticationContract {
  /** @format uuid */
  id?: string
  token?: string | null
  /** @format uuid */
  tabletId?: string | null
  isActive?: boolean
  /** @format int32 */
  timeZoneOffset?: number
  ageVerificationType?: AgeVerificationType
  /** @format int32 */
  ageVerificationCountdownSeconds?: number
}

export interface LabelContract {
  /** @format uuid */
  id?: string
  text?: string | null
  cornersStyle?: LabelCornersStyle
  textColor?: string | null
  backgroundColor?: string | null
}

export enum LabelCornersStyle {
  Round = 'round',
  Square = 'square',
}

export enum LanguageCode {
  Lt = 'lt',
  En = 'en',
  Et = 'et',
  Lv = 'lv',
  Sv = 'sv',
  Fi = 'fi',
  No = 'no',
  Ru = 'ru',
  Pl = 'pl',
  Fr = 'fr',
  Da = 'da',
  Tr = 'tr',
}

export interface LanguageContract {
  code?: LanguageCode
  title?: string | null
  image?: string | null
}

export interface LogContract {
  /** @minLength 1 */
  message: string
  recipients: string[]
}

export interface LogTabletStatusContract {
  /** @format double */
  changedAtTimestamp: number
  terminalStatus?: TabletTerminalStatus
  printerStatus?: TabletPrinterStatus
  networkConnected?: boolean
  /** @format int32 */
  batteryLevel?: number
  pushNotificationsEnabled?: boolean
  kitchenPrinterStatus?: TabletPrinterStatus
}

export interface LoyaltyAdminContract {
  /** @format uuid */
  id?: string
  email?: string | null
  restaurantIds?: string[] | null
  images?: string[] | null
  role?: LoyaltyAdminRole
  companyName?: string | null
  companyCode?: string | null
  /** @format uuid */
  customizationId?: string | null
  /** @format double */
  maximumDiscountAmount?: number | null
  isDisabled?: boolean
}

export enum LoyaltyAdminRole {
  User = 'user',
  Admin = 'admin',
  Master = 'master',
}

export interface LoyaltyAdminUpdateContract {
  restaurantIds?: string[] | null
  /** @format double */
  maximumDiscountAmount?: number | null
  isDisabled?: boolean | null
}

export interface LoyaltyAdminUpdatePasswordContract {
  /** @minLength 9 */
  password: string
  /** @minLength 1 */
  confirmPassword: string
}

export interface LoyaltyAnalyticsReportContract {
  /** @format date-time */
  date?: string
  /** @format uuid */
  restaurantId?: string
  restaurantTitle?: string | null
  /** @format uuid */
  organizationId?: string
  /** @format uuid */
  loyaltyCardId?: string
  code?: string | null
  email?: string | null
  /** @format uuid */
  customizationId?: string | null
  customizationTitle?: string | null
  companyName?: string | null
  companyCode?: string | null
  description?: string | null
  /** @format date-time */
  validTo?: string
  /** @format double */
  totalSpend?: number
  /** @format double */
  monthlyLimit?: number
  isDisabled?: boolean
  /** @format double */
  exceededAmount?: number
  /** @format int32 */
  totalOrdersCount?: number
  /** @format int32 */
  totalAmount?: number
  /** @format date-time */
  saleDate?: string | null
  /** @format date-time */
  createdDate?: string | null
}

export interface LoyaltyAuthenticationContract {
  /** @format uuid */
  id?: string
  token?: string | null
  email?: string | null
  restaurants?: LoyaltyVenueContract[] | null
  loyaltyCards?: LoyaltyCardContract[] | null
  images?: string[] | null
  role?: LoyaltyAdminRole
  companyName?: string | null
  companyCode?: string | null
  /** @format uuid */
  customizationId?: string | null
  /** @format double */
  maximumDiscountAmount?: number | null
  isDisabled?: boolean
}

export interface LoyaltyCardContract {
  /** @format uuid */
  adminId?: string
  /** @format uuid */
  loyaltyCardId?: string
  /** @format double */
  credits?: number
  code?: string | null
  email?: string | null
  name?: string | null
  isDisabled?: boolean
  pkPassName?: string | null
  appleWalletUrl?: string | null
  googleWalletUrl?: string | null
  discount?: LoyaltyDiscountDetailsContract
  /** @format uuid */
  customizationId?: string | null
  restaurantIds?: string[] | null
  companyName?: string | null
  companyCode?: string | null
  description?: string | null
}

export interface LoyaltyCardDetailsContract {
  /** @format uuid */
  adminId?: string
  /** @format uuid */
  loyaltyCardId?: string
  /** @format double */
  credits?: number
  code?: string | null
  email?: string | null
  name?: string | null
  isDisabled?: boolean
  pkPassName?: string | null
  appleWalletUrl?: string | null
  googleWalletUrl?: string | null
  discount?: LoyaltyDiscountDetailsContract
  /** @format uuid */
  customizationId?: string | null
  restaurantIds?: string[] | null
  companyName?: string | null
  companyCode?: string | null
  description?: string | null
  customizationProfile?: LoyaltyCustomizationContract
}

export interface LoyaltyCustomizationContract {
  /** @format uuid */
  id?: string
  organizationName?: string | null
  name?: string | null
  appleLogoUrl?: string | null
  appleStripImageUrl?: string | null
  googleLogoUrl?: string | null
  googleStripImageUrl?: string | null
  backgroundColor?: string | null
  email?: string | null
  webpage?: string | null
  phoneNumber?: string | null
  font?: string | null
  walletCardTitle?: string | null
  /** @format int32 */
  validForMonths?: number | null
  firstHeaderText?: string | null
  firstBodyText?: string | null
  secondHeaderText?: string | null
  secondBodyText?: string | null
  bottomBodyText?: string | null
  footerText?: string | null
  firstViewCardLinkText?: string | null
  secondViewCardLinkText?: string | null
  creditsLeftText?: string | null
  emailSenderName?: string | null
}

export interface LoyaltyDiscountDetailsContract {
  /** @format uuid */
  id?: string
  /** @format double */
  maxAmount?: number | null
  /** @format int32 */
  orderCount?: number | null
  /** @format int32 */
  dailyOrderCount?: number | null
  /** @format int32 */
  totalAmount?: number | null
  /** @format int32 */
  totalMonthlyAmount?: number | null
  /** @format date-time */
  validTo?: string
}

export interface LoyaltyFontContract {
  title?: string | null
}

export interface LoyaltyOrganizationModel {
  /** @format uuid */
  id?: string
  title?: string | null
  venues?: LoyaltyVenueModel[] | null
}

export interface LoyaltyVenueContract {
  /** @format uuid */
  id?: string
  title?: string | null
  address?: string | null
}

export interface LoyaltyVenueModel {
  /** @format uuid */
  id?: string
  title?: string | null
  address?: string | null
}

export enum MenuTvPriceLocation {
  TopRight = 'topRight',
  TopLeft = 'topLeft',
}

export interface MontonioV2OrderWebhook {
  orderToken?: string | null
}

export enum NetworkProtocol {
  Http = 'http',
  Https = 'https',
}

export interface OptionSetContract {
  /** @format uuid */
  id?: string
  title?: string | null
  type?: OptionSetType
  /** @format int32 */
  minSelections?: number
  /** @format int32 */
  maxSelections?: number
  /** @format int32 */
  order?: number
  labels?: LabelContract[] | null
  options?: ProductContract[] | null
}

export enum OptionSetType {
  Single = 'single',
  Multi = 'multi',
  Extra = 'extra',
}

export interface OrderAnalyticsContract {
  /** @format double */
  firstClickTimeStamp?: number | null
  /** @format double */
  cartClickTimeStamp?: number | null
  /** @format double */
  payClickTimeStamp?: number | null
  /** @format double */
  orderCreatedTimeStamp?: number | null
  /** @format double */
  terminalReadyForPaymentTimeStamp?: number | null
  /** @format double */
  terminalTransactionStartedTimeStamp?: number | null
  /** @format double */
  terminalTransactionCompletedTimeStamp?: number | null
  /** @format double */
  printFinishTimeStamp?: number | null
  /** @format double */
  orderFinishTimeStamp?: number | null
}

export interface OrderContract {
  /** @format uuid */
  id?: string
  number?: string | null
  type?: OrderType
  status?: OrderStatus
  commentToKitchen?: string | null
  name?: string | null
  promoCodeImageName?: string | null
  /** @format double */
  totalPrice?: number
  /** @format double */
  serviceFeeAmount?: number
  /** @format double */
  promoCodeDiscount?: number
  /** @format double */
  discountAmount?: number
  /** @format double */
  startTimeStamp?: number
  products?: ProductContract[] | null
  circleKXepsOrder?: string | null
  paymentStatus?: PaymentStatus
  paymentType?: PaymentType
  externalPaymentLink?: string | null
  promoCode?: string | null
  /** @format double */
  takeAwayDiscountAmount?: number
  paymentConfirmationQr?: string | null
  loyaltyCode?: string | null
  /** @format double */
  loyaltyDiscountAmount?: number
  /** @format double */
  loyaltyPaidAmount?: number
  guestIdentification?: string | null
  userPhoneNumber?: string | null
  /** @format double */
  tipAmount?: number
  fiscalReceipt?: FiscalReceiptContract
}

export interface OrderFailureContract {
  failureReason?: OrderFailureReason
  message?: string | null
}

export enum OrderFailureReason {
  PaymentFailed = 'paymentFailed',
  PrinterFailed = 'printerFailed',
  MarkPaidFailed = 'markPaidFailed',
  KitchenPrinterFailed = 'kitchenPrinterFailed',
  ExternalApiFailure = 'externalApiFailure',
}

export enum OrderOrigin {
  SelfService = 'selfService',
  Wolt = 'wolt',
  Deliverect = 'deliverect',
  Qr = 'qr',
  Bolt = 'bolt',
  Web = 'web',
  SyrveDelivery = 'syrveDelivery',
  OrderProcessor = 'orderProcessor',
}

export interface OrderPayContract {
  transactionTimeStamp?: string | null
  paymentId?: string | null
  receipt?: string | null
  rrn?: string | null
}

export interface OrderPriceContract {
  /** @format double */
  totalPrice?: number
  /** @format double */
  salesTaxAmount?: number
  promoCode?: string | null
  /** @format double */
  promoCodeDiscount?: number
  /** @format double */
  discount?: number
  /** @format double */
  takeAwayDiscountAmount?: number
  /** @format double */
  tipAmount?: number
  /** @format double */
  availableLoyaltyAmount?: number
}

export interface OrderReportContract {
  /** @format uuid */
  id?: string
  /** @format int32 */
  number?: number | null
  /** @format uuid */
  tabletId?: string
  tablet?: string | null
  orderDate?: string | null
  orderTime?: string | null
  orderDateTime?: string | null
  organizationTitle?: string | null
  /** @format uuid */
  organizationId?: string
  restaurantTitle?: string | null
  /** @format uuid */
  restaurantId?: string
  type?: OrderType
  paymentType?: PaymentType
  clientCode?: string | null
  clientName?: string | null
  /** @format uuid */
  clientId?: string | null
  /** @format uuid */
  promoCodeId?: string | null
  promoCodeTitle?: string | null
  promoCodeDescription?: string | null
  commentToKitchen?: string | null
  /** @format double */
  baseSalesWithVat?: number
  /** @format double */
  totalDiscountAmount?: number
  /** @format double */
  totalPrice?: number
  /** @format double */
  serviceFeeAmount?: number
  /** @format double */
  salesTaxAmount?: number
  /** @format double */
  promoCodeDiscount?: number
  /** @format double */
  discountAmount?: number
  /** @format double */
  takeAwayDiscountAmount?: number | null
  origin?: OrderOrigin
  loyaltyCode?: string | null
  /** @format double */
  loyaltyPaidAmount?: number | null
  companyCode?: string | null
  /** @format double */
  transactionAmount?: number
  name?: string | null
}

export enum OrderStatus {
  Created = 'created',
  Finished = 'finished',
  Canceled = 'canceled',
}

export enum OrderType {
  Table = 'table',
  TakeAway = 'takeAway',
}

export interface OrderValidationErrorContract {
  disabledProductIds?: string[] | null
  updatedProducts?: ProductContract[] | null
}

export enum OtOrderType {
  Table = 'table',
  TakeAway = 'takeAway',
  Delivery = 'delivery',
}

export enum PassType {
  None = 'none',
  GPass = 'gPass',
  Test = 'test',
  Recovery = 'recovery',
  Vaccination = 'vaccination',
}

export enum PaymentStatus {
  Unpaid = 'unpaid',
  Paid = 'paid',
  Refunded = 'refunded',
  Pending = 'pending',
}

export enum PaymentType {
  Card = 'card',
  Cashier = 'cashier',
  ClientCode = 'clientCode',
  Waiter = 'waiter',
  WebTransfer = 'webTransfer',
  WebCard = 'webCard',
  Web = 'web',
}

export enum PrintStatus {
  Created = 'created',
  Printed = 'printed',
}

export enum PrinterProtocol {
  Tcp = 'tcp',
  Usb = 'usb',
  Http = 'http',
  Https = 'https',
}

export enum PrinterType {
  None = 'none',
  EpsonT20III = 'epsonT20III',
  StarTsp100III = 'starTsp100III',
  SewooSLKTS400 = 'sewooSLKTS400',
  EpsonTMM30 = 'epsonTMM30',
  EpsonTMM30Scan = 'epsonTMM30Scan',
  CitizenCTE351 = 'citizenCTE351',
  PrintApi = 'printApi',
}

export enum ProductAction {
  None = 'none',
  Add = 'add',
  Remove = 'remove',
}

export enum ProductAddedFrom {
  Menu = 'menu',
  Search = 'search',
  Scan = 'scan',
  UpsellProduct = 'upsellProduct',
  UpsellCheckout = 'upsellCheckout',
  UpsellStart = 'upsellStart',
}

export interface ProductCategoryContract {
  /** @format uuid */
  id?: string
  /** @format uuid */
  masterCategoryId?: string | null
  title?: string | null
  imageName?: string | null
}

export interface ProductContract {
  /** @format uuid */
  id?: string
  /** @format uuid */
  orderProductId?: string
  /** @format uuid */
  categoryId?: string
  productType?: ProductType
  title?: string | null
  description?: string | null
  /** @format double */
  price?: number
  /** @format double */
  discountAmount?: number
  /** @format double */
  takeAwayPrice?: number | null
  /** @format double */
  takeAwayDiscountAmount?: number
  /** @format double */
  salesTaxAmount?: number
  upsellProductIds?: string[] | null
  labels?: LabelContract[] | null
  /** @format int32 */
  ageToComply?: number | null
  images?: string[] | null
  /** @format int32 */
  count?: number | null
  enableKitchenPrinter?: boolean
  barcode?: string | null
  isDeposit?: boolean
  isRestricted?: boolean
  optionSets?: OptionSetContract[] | null
  tagsIds?: string[] | null
}

export interface ProductReportContract {
  /** @format uuid */
  organizationId?: string
  /** @format uuid */
  restaurantId?: string
  /** @format uuid */
  orderId?: string
  restaurantTitle?: string | null
  /** @format int32 */
  count?: number
  title?: string | null
  categoryTitle?: string | null
  /** @format uuid */
  categoryId?: string
  externalId?: string | null
  barcode?: string | null
  /** @format double */
  price?: number
  /** @format double */
  sales?: number
  isDeposit?: boolean
}

export interface ProductSearchContract {
  /** @minLength 1 */
  query: string
}

export interface ProductToggleContract {
  /** @format uuid */
  organizationId?: string
  /** @format uuid */
  restaurantId?: string | null
  organizationTitle?: string | null
  restaurantTitle?: string | null
  entries?: ProductToggleEntryContract[] | null
}

export interface ProductToggleEntryContract {
  /** @format uuid */
  productId?: string
  productTitle?: string | null
  adminEmail?: string | null
  isEnabled?: boolean
  dateTime?: string | null
  /** @format uuid */
  productCategoryId?: string | null
  externalId?: string | null
}

export enum ProductType {
  Unit = 'unit',
  Weighed = 'weighed',
  LoyaltyCard = 'loyaltyCard',
  FlexiblePrice = 'flexiblePrice',
}

export interface QrCatalogContract {
  products?: ProductContract[] | null
  upsellProducts?: ProductContract[] | null
  categories?: ProductCategoryContract[] | null
  masterCategories?: ProductCategoryContract[] | null
  tags?: TagContract[] | null
}

export interface QrGroupContract {
  /** @format uuid */
  id?: string
  title?: string | null
  languageCode?: LanguageCode
  venues?: QrVenueContract[] | null
}

export interface QrTabletContract {
  /** @format uuid */
  id?: string
  title?: string | null
  allowCashierPayment?: boolean
  allowClientCodePayment?: boolean
  allowWaiterPayment?: boolean
  allowWebPayment?: boolean
}

export enum QrTitleDisplayType {
  VenueTitle = 'venueTitle',
  None = 'none',
  TabletTitle = 'tabletTitle',
  Address = 'address',
  VenueAndTabletTitle = 'venueAndTabletTitle',
}

export interface QrUserProfileContract {
  /** @format uuid */
  id?: string
  isActive?: boolean
  isOpen?: boolean
  restaurantImage?: string | null
  restaurantImages?: string[] | null
  restaurantImagesMobile?: string[] | null
  restaurantTitle?: string | null
  restaurantDisplayTitle?: string | null
  restaurantAddress?: string | null
  languages?: LanguageContract[] | null
  restaurantSchedules?: RestaurantScheduleContract[] | null
  /** @format double */
  serviceFee?: number
  allowPromoCodes?: boolean
  isAcceptingTableOrders?: boolean
  isAcceptingTakeAwayOrders?: boolean
  tablet?: QrTabletContract
  requiredUserInput?: boolean
  enablePaymentConfirmationQrPrinting?: boolean
  enableAppraisal?: boolean
  /** @deprecated */
  enableQrInvoiceEmail?: boolean
  enableSupportChat?: boolean
  webPaymentProvider?: WebPaymentProvider
  enableWebTransferPayment?: boolean
  enableWebCardPayment?: boolean
  enableOrderComment?: boolean
  guestIdentificationInputType?: InputFieldType
  qrInvoiceEmailInputType?: InputFieldType
  showOrderHistory?: boolean
  currency?: Currency
  phoneNumberInputType?: InputFieldType
  callingCodes?: CallingCodeContract[] | null
  enableTimeSlots?: boolean
  receiptImage?: string | null
  receiptText?: string | null
  allergensUrl?: string | null
  hideCurrencySymbol?: boolean
  enableWebshopMasterCategories?: boolean
  tipsInputType?: InputFieldType
  /** @format double */
  latitude?: number | null
  /** @format double */
  longitude?: number | null
  qrTitleDisplayType?: QrTitleDisplayType
  ignoreRestaurantsModal?: boolean
}

export interface QrVenueContract {
  /** @format uuid */
  id?: string
  title?: string | null
  address?: string | null
  restaurantDisplayTitle?: string | null
  /** @format uuid */
  tabletId?: string
  /** @format double */
  latitude?: number | null
  /** @format double */
  longitude?: number | null
  restaurantSchedules?: RestaurantScheduleContract[] | null
  domain?: string | null
}

export interface RateAppraisalContract {
  /**
   * @format int32
   * @min 0
   * @exclusiveMin true
   * @max 5
   */
  rating?: number
  reason?: string | null
}

export interface RateOrderContract {
  /** @format int32 */
  rating?: number
}

export interface RatingsReportContract {
  /** @format uuid */
  organizationId?: string
  /** @format uuid */
  restaurantId?: string
  /** @format uuid */
  orderId?: string | null
  restaurantTitle?: string | null
  origin?: FeedbackOrigin
  /** @format int32 */
  rating?: number
  reason?: string | null
  message?: string | null
  imageName?: string | null
  dateTime?: string | null
  category?: string | null
  orderName?: string | null
}

export interface RefundOrderContract {
  paymentId?: string | null
  /** @format double */
  amount?: number
}

export interface RegisterLoyaltyAdminContract {
  /**
   * @format email
   * @minLength 1
   */
  email: string
  /** @minLength 1 */
  password: string
  restaurantIds?: string[] | null
  companyName?: string | null
  companyCode?: string | null
  /** @format double */
  maximumDiscountAmount?: number | null
}

export interface RegisterLoyaltyMasterAdminContract {
  /**
   * @format email
   * @minLength 1
   */
  email: string
  /** @minLength 1 */
  password: string
}

export interface RegisterLoyaltyUserContract {
  /**
   * @format email
   * @minLength 1
   */
  email: string
  /** @minLength 1 */
  password: string
  restaurantIds?: string[] | null
  /** @minLength 1 */
  companyName: string
  /** @minLength 1 */
  companyCode: string
  /**
   * @format uuid
   * @minLength 1
   */
  managingAdminId: string
  /**
   * @format uuid
   * @minLength 1
   */
  customizationId: string
  /** @format double */
  maximumDiscountAmount?: number | null
}

export interface RenewMenuCacheContract {
  /** @format uuid */
  organizationId?: string
  /** @format uuid */
  restaurantId?: string | null
}

export enum ReportDuration {
  OneDay = 'oneDay',
  OneHour = 'oneHour',
  HalfHour = 'halfHour',
}

export interface ReservedTimeSlotContract {
  /** @format uuid */
  id?: string
  /** @format double */
  expiration?: number
}

export interface RestaurantScheduleContract {
  /** @format date-span */
  startTime?: string
  /** @format date-span */
  endTime?: string
  dayOfWeek?: DayOfWeek
}

export enum ScaleType {
  None = 'none',
  Api = 'api',
}

export interface SearchResultContract {
  products?: ProductContract[] | null
  upsellProducts?: ProductContract[] | null
}

export interface SecretMenuContract {
  items?: SecretMenuPrivilege[] | null
  isLocked?: boolean
}

export enum SecretMenuPrivilege {
  Restart = 'restart',
  Logout = 'logout',
  PaxTerminalRePair = 'paxTerminalRePair',
  WorldineLogs = 'worldineLogs',
  WorldineVoid = 'worldineVoid',
  WorldineRefund = 'worldineRefund',
  WordlineDayClosure = 'wordlineDayClosure',
  EpsDayReport = 'epsDayReport',
  TabletInfo = 'tabletInfo',
  Unlock = 'unlock',
  UpdateApp = 'updateApp',
  UpdateOs = 'updateOs',
}

export interface SelfServiceAuthenticationContract {
  token?: string | null
  user?: SelfServiceUserContract
}

export interface SelfServiceUserContract {
  /** @format uuid */
  id?: string
  email?: string | null
  tablets?: TabletContract[] | null
  callingCodes?: CallingCodeContract[] | null
  languages?: LanguageContract[] | null
  /** @format double */
  serviceFee?: number
  portraitImages?: string[] | null
  landscapeImages?: string[] | null
  receiptImage?: string | null
  promoImage?: string | null
  receiptText?: string | null
  /** @format date-span */
  dayClosureTime?: string | null
  allowPromoCodes?: boolean
  showPromoCodeScanner?: boolean
  isAcceptingTableOrders?: boolean
  isAcceptingTakeAwayOrders?: boolean
  showOrderHistory?: boolean
  isActive?: boolean
  /** @format int32 */
  timeZoneOffset?: number
  enableProductScanner?: boolean
  enableQuickProductScanner?: boolean
  enableOrderComment?: boolean
  calculateDiscountBeforePayment?: boolean
  enableStartUpsell?: boolean
  enableProductUpsell?: boolean
  enableCheckoutUpsell?: boolean
  enableSalesTax?: boolean
  /** @format int32 */
  cartSizeLimit?: number | null
  premiumScannerKey?: string | null
  kitchenPrinterType?: PrinterType
  kitchenPrinterIp?: string | null
  currency?: Currency
  isCovidPassRequired?: boolean
  covidScanType?: CovidScanType
  customizationProfile?: CustomizationProfileContract
  ageVerificationType?: AgeVerificationType
  serverSwichCode?: string | null
  allergensUrl?: string | null
  enableRatings?: boolean
  requiredUserInput?: boolean
  enablePaymentConfirmationQrPrinting?: boolean
  /** @deprecated */
  enableTabletInvoiceEmail?: boolean
  disableFirstScreen?: boolean
  receiptLanguageCode?: LanguageCode
  enableLoyaltyCardScanner?: boolean
  guestIdentificationInputType?: InputFieldType
  tabletInvoiceEmailInputType?: InputFieldType
  phoneNumberInputType?: InputFieldType
  allowPhoneOrderPromoCodes?: boolean
  hideCurrencySymbol?: boolean
  isDynamicOrder?: boolean
}

export interface SendOrderFiscalReceiptContract {
  /**
   * @format email
   * @minLength 1
   */
  email: string
}

export interface SendOrderInvoiceContract {
  /**
   * @format uuid
   * @minLength 1
   */
  orderId: string
  /**
   * @format email
   * @minLength 1
   */
  email: string
}

export interface StaticCashierAgeVerificationStartContract {
  /** @format int32 */
  ageToVerify?: number
  imageName?: string | null
  verificationId?: string | null
}

export interface StaticCashierAgeVerificationUpdateContract {
  imageName?: string | null
}

export interface StringContract {
  value?: string | null
}

export interface TabletContract {
  /** @format uuid */
  id?: string
  title?: string | null
  terminalIp?: string | null
  terminalPort?: string | null
  terminalType?: TerminalType
  printerIp?: string | null
  printerType?: PrinterType
  printerProtocol?: PrinterProtocol
  allowCardPayment?: boolean
  allowCashierPayment?: boolean
  allowClientCodePayment?: boolean
  allowWaiterPayment?: boolean
  isPhoneNumberRequired?: boolean
  enableOrderOptimization?: boolean
  enablePremiumScanner?: boolean
  xepsClientId?: string | null
  xepsAuthToken?: string | null
  xepsEventUrl?: string | null
  terminalId?: string | null
  terminalAuthToken?: string | null
  terminalProjectId?: string | null
  scaleType?: ScaleType
  scaleIp?: string | null
  scaleProtocol?: NetworkProtocol
  enablePhoneOrderButton?: boolean
}

export enum TabletPrinterStatus {
  Ok = 'ok',
  NoSetup = 'noSetup',
  NoPaper = 'noPaper',
  OpenCover = 'openCover',
  NotAvailable = 'notAvailable',
}

export interface TabletStatusContract {
  /** @format uuid */
  tabletId?: string
  /** @format double */
  changedAtTimestamp?: number
  terminalStatus?: TabletTerminalStatus
  printerStatus?: TabletPrinterStatus
  networkConnected?: boolean
  /** @format int32 */
  batteryLevel?: number
  pushNotificationsEnabled?: boolean
  kitchenPrinterStatus?: TabletPrinterStatus
}

export enum TabletTerminalStatus {
  Ok = 'ok',
  NoSetup = 'noSetup',
  IpMismatch = 'ipMismatch',
  SetlementRequired = 'setlementRequired',
  NotConnected = 'notConnected',
  Busy = 'busy',
  InvalidSetup = 'invalidSetup',
}

export interface TagContract {
  /** @format uuid */
  id?: string
  title?: string | null
  textColor?: string | null
  backgroundColor?: string | null
}

export enum TerminalType {
  None = 'none',
  CircleK = 'circleK',
  WorldLine = 'worldLine',
  Pax = 'pax',
  Eps = 'eps',
  Adyen = 'adyen',
}

export interface TimeSlotContract {
  /** @format uuid */
  id?: string
  /** @format date-time */
  date?: string
  /** @format int32 */
  totalSlots?: number
}

export interface TipAppraisalContract {
  /**
   * @format double
   * @min 0
   * @exclusiveMin true
   */
  tipAmount?: number
  paymentType?: AppraisalPaymentType
}

export interface ToggleProductContract {
  isDisabled?: boolean
  productIds?: string[] | null
}

export interface TransactionLogContract {
  orderId?: string | null
  terminalId?: string | null
  transactionId?: string | null
  message?: string | null
}

export interface TranslationContract {
  /** @format uuid */
  id?: string
  term?: string | null
  translations?: Record<string, string | null>
}

export interface TvCatalogContract {
  priceCardColor?: string | null
  priceTextColor?: string | null
  priceLocation?: MenuTvPriceLocation
  footerText?: string | null
  categories?: TvCategoryContract[] | null
}

export interface TvCategoryContract {
  imageName?: string | null
  color?: string | null
  products?: TvProductContract[] | null
}

export interface TvProductContract {
  /** @format uuid */
  id?: string
  title?: string | null
  description?: string | null
  /** @format double */
  price?: number
  image?: string | null
  /** @format double */
  priceAsOption?: number
}

export interface TwilioTokenContract {
  accessToken?: string | null
  /** @format double */
  validToTimeStamp?: number
}

export interface UpdateDynamicOrderContract {
  updatedProducts?: CreateOrderProductContract[] | null
  type?: OrderType
  userPhoneNumber?: string | null
  commentToKitchen?: string | null
  isUserAgeConfirmed?: boolean
  sales?: UpsellInfoContract[] | null
  checkoutUpsellProductIds?: string[] | null
  invoiceEmail?: string | null
  /** @format double */
  takeAwayDiscountAmount?: number
  pagerNumber?: string | null
  promoCode?: string | null
}

export interface UpdateDynamicOrderLoyaltyInfoContract {
  vouchers?: CircleKXepsVoucherContract[] | null
  loyaltyLevels?: string[] | null
}

export interface UpdateLoyaltyCardBulkContract {
  /** @minItems 1 */
  ids: string[]
  /** @format double */
  validTo?: number | null
  /** @format uuid */
  customizationId?: string | null
  /** @format int32 */
  totalMonthlyAmount?: number | null
}

export interface UpdateLoyaltyDetailsContract {
  /** @format double */
  maxAmount?: number | null
  /** @format int32 */
  orderCount?: number | null
  /** @format int32 */
  dailyOrderCount?: number | null
  /** @format int32 */
  totalAmount?: number | null
  /** @format int32 */
  totalMonthlyAmount?: number | null
  /** @format double */
  validTo: number
  restaurantIds?: string[] | null
}

export interface UpdateTabletPrinterContract {
  printerIp?: string | null
  printerType?: PrinterType
  printerProtocol?: PrinterProtocol
}

export interface UpdateTabletTerminalContract {
  terminalId?: string | null
  terminalAuthToken?: string | null
}

export interface UpsellInfoContract {
  /** @format uuid */
  productId?: string
  addedFrom?: ProductAddedFrom
  /** @format uuid */
  addedFromId?: string | null
  /** @format int32 */
  count?: number
  upsellProducts?: string[] | null
  upselledWith?: string[] | null
}

export interface UpsellReportContract {
  /** @format uuid */
  organizationId?: string
  organizationTitle?: string | null
  /** @format uuid */
  restaurantId?: string
  restaurantTitle?: string | null
  /** @format uuid */
  productId?: string
  productTitle?: string | null
  /** @format double */
  productPrice?: number
  /** @format int32 */
  productQuantity?: number
  origin?: string | null
  /** @format uuid */
  parentProductId?: string | null
  parentProductTitle?: string | null
  /** @format uuid */
  orderId?: string
  dateTime?: string | null
}

export interface ValidationErrorContract {
  /** @minLength 1 */
  fieldName: string
  /** @minLength 1 */
  errorMessage: string
}

export interface VerifyClientContract {
  /** @format int64 */
  clientCode: number
  /** @format double */
  totalPrice?: number
}

export interface VerifyExternalLoyaltyCustomerContract {
  /** @minLength 4 */
  code: string
}

export interface VerifyLoyaltyCodeContract {
  /** @minLength 1 */
  loyaltyCode: string
  /** @format double */
  userVisiblePrice?: number
}

export interface VerifyLoyaltyCodeResultContract {
  loyaltyCode?: string | null
  /** @format int64 */
  clientCode?: number | null
  additionalPaymentRequired?: boolean
  loyaltyCardInfo?: BarcodeResultLoyaltyCardContract
}

export enum WebPaymentProvider {
  Montonio = 'montonio',
  MontonioV2 = 'montonioV2',
  Adyen = 'adyen',
}
